import { render, staticRenderFns } from "./videoChater.vue?vue&type=template&id=593e1140&scoped=true&"
import script from "./videoChater.vue?vue&type=script&lang=js&"
export * from "./videoChater.vue?vue&type=script&lang=js&"
import style0 from "./videoChater.vue?vue&type=style&index=0&id=593e1140&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "593e1140",
  null
  
)

export default component.exports