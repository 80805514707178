<template>
  <div class="warpConss">
    <!-- {{isOutsourcing+'sssss'}} -->
    <el-collapse v-model="openIndex" accordion>
      <el-collapse-item v-for="(grounp, grounpIndex) in CourseList" :key="grounpIndex" :name="grounpIndex">
        <template slot="title">
          <div class="title-grounp">
            <div class="dian" />
            <span class="ellipsis"> {{ grounp.title }}</span>
          </div>
        </template>
        <div v-for="(item, index) in grounp.list" :key="index" class="chat-item"
          :class="(item.audition && item.audition != '0') || IsPurchase ? 'canVideo' : ''">
          <div class="item-title" @click.stop="check(item, grounp, grounpIndex)">
            <i v-if="(item.audition && item.audition != '0') || IsPurchase" class="iconfont icon-a-Union21" />
            <i v-else class="iconfont icon-suotou" />
            <div class="title ellipsis">
              {{ item.title }}
            </div>
            <span v-if="item.audition != '0' && !IsPurchase && item.audition != '' && item.audition != null"
              class="audition">可试听</span>
          </div>
          <div class="but-list">
            <div :class="(item.audition && item.audition != '0') || IsPurchase ? '' : 'noLives'">
              <div v-if="ifClass ? dealDocType(item, 2) : item.isHasContantJY == 'true'" class="keep-learn"
                @click.stop="ktData(item, 5)">
                随堂讲义
              </div>
              <div v-if="ifClass ? item.contactList && item.contactList.length > 0 : item.isHasContant == 'true'"
                class="keep-learn" @click.stop="cgeckTask(item)">
                随堂测试
              </div>
              <div v-if="ifClass ? dealDocType(item, 1) : item.isHasDoc == 'true'" class="keep-learn"
                @click.stop="ktData(item, 3)">
                随堂资料
              </div>
            </div>
            <!--   <div class="keep-learn" v-if="item.isHasContant == 'true'" @click.stop="ktData(item, 5)">随堂讲义</div>
            <div class="keep-learn" v-if="item.isHasContant == 'true'" @click.stop="cgeckTask(item)">随堂测试</div>
            <div class="keep-learn" v-if="item.isHasDoc == 'true'" @click.stop="ktData(item, 3)">随堂资料</div> -->
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div v-if="dialogVisible" class="warpCpons" @touchmove.stop.prevent="stop()">
      <div>
        <img class="imga" src="@/assets/img/kecheng/No1.png">
        <img class="imgb" src="@/assets/img/kecheng/close.png" @click.stop="tChuang">
        <span>您暂未购买该课程哦~</span>
      </div>
    </div>
    <el-dialog title="" custom-class="dpPlay" :visible.sync="ifDialog" :show-close="false" width="80%" append-to-body>
      <iframe :src="`${href}/pages/videoRoom?videoId=${id}&id=${dialogList ? dialogList.id : ''
        }&domainjwt=${domainjwt}&userId=${userInfo ? userInfo.id : ''}&classId=${classId}`" width="100%" height="721px"
        frameborder="0" noResize />
    </el-dialog>
    <!-- 是否可以观看弹窗 -->
    <div v-if="showLookDetail" class="lookBg">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : "" }}</span>观看<span>{{ lookDeatil ? lookDeatil.chapterName : ""
          }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getClassCourseById } from '@/api/home'
import { IsLive } from '@/api/emun'
import { getInfo, getDomainToken } from '@/api/cookies'
import { AnswerClient } from '@/api/task/answerClient'
import { courseCheckPlay } from '@/api/know'
const answerClient = new AnswerClient()

export default {
  props: {
    id: {},
    playid: {},
    list: {},
    IsPurchase: {
      type: Boolean
    },
    classId: {},
    classProductLine: {},
    isOutsourcing: {
      type: Number
    },

    ifClass: {
      type: Boolean
    },
    cardCode: null,
    isFreeze: null
  },
  data() {
    return {
      userInfo: null,
      IsLive: IsLive,
      CourseList: [],
      dialogVisible: false,
      ifDialog: false,
      href: process.env.VUE_APP_BASE_DpPlay,
      dialogList: null,
      domainjwt: getDomainToken(),
      openIndex: 0,
      lookDeatil: null,
      showLookDetail: false
    }
  },
  watch: {
    list() {
      this.CourseList = this.list
      for (const item of this.CourseList) {
        if (!item.list) {
          item.list = item.videoList
        }
      }
    }
  },
  created() {
    this.userInfo = getInfo()
    if (this.list) {
      this.CourseList = this.list
      for (const item of this.CourseList) {
        if (!item.list) {
          item.list = item.sectionList
        }
        item.audition = false
        for (const chil of item.list) {
          if (chil.audition && chil.audition != '0') {
            item.audition = true
            break
          }
        }
      }
    } else {
      getClassCourseById(this.id).then((res) => {
        this.CourseList = res.data.details.list
        for (const item of this.CourseList) {
          item.audition = false
          for (const chil of item.list) {
            if (chil.audition && chil.audition != '0') {
              item.audition = true
              break
            }
          }
        }
      })
      this.$forceUpdate()
    }
  },
  methods: {
    /* 判断是否有讲义 */
    dealDocType(item, type) {
      let temp = false
      if (item.docList && item.docList.length > 0) {
        item.docList.forEach((element) => {
          if (element.docType == type) {
            temp = true
          }
        })
      }
      return temp
    },
    tChuang() {
      this.dialogVisible = false
    },
    stop() {
      return
    },
    // 观看回放
    check(item, groupId, index) {
      const userInfo = getInfo()
      const sub = {
        id: item.id,
        uservideoConfig: item.videoConfig,
        courseId: this.id,
        classId: this.classId,
        groupId: groupId.id,
        typebs: 2,
        IsPurchase: this.IsPurchase,
        classProductLine: this.classProductLine,
        chapterName: item.title
      }
      const data = Vue.prototype.export(sub)
      // 未登录可以直接去试听
      if (!userInfo) {
        if (item.audition != '0' && !this.IsPurchase && item.audition != '' && item.audition != null) {
          this.$router.push(`/dianbofang?${data}&openIndex=${index}`)
          return
        }
        Vue.prototype.goLoginView(false)
        return
      } else {
        // 已登录试听
        if (item.audition != '0' && !this.IsPurchase && item.audition != '' && item.audition != null) {

          this.limitCheck(item, data, index)
          return
        }
      }
      if (this.isFreeze == 1) {
        this.$message.error('课程已冻结')
        return
      }

      if (
        !this.IsPurchase &&
        (!item.audition || item.audition == '0' || item.audition == '' || item.audition == null)
      ) {
        this.dialogVisible = true
        this.$forceUpdate()
        return
      }
      if (this.isOutsourcing == 1) {
        this.ifDialog = true
        this.dialogList = item
      } else {

        this.limitCheck(item, data, index)
      }
    },
    // 看课限制
    async limitCheck(item, data, index) {
      const { code, msg } = await courseCheckPlay({
        courseType: '2',
        userId: this.userInfo.id,
        chapterId: item.id,
        source: 1
      })

      if (code == 0) {
        this.$router.push(`/dianbofang?${data}&openIndex=${index}`)
        // window.open(`/dianbofang?${data}&openIndex=${index}`, `_blank`)
      } else {
        this.lookDeatil = msg
        this.showLookDetail = true
      }
    },
    // 课堂作业
    cgeckTask(item) {
      let sId = item.sId
      let title = item.title
      if (this.ifClass) {
        title = item.contactList[0].sjName
        sId = item.contactList[0].cContactid
      }

      answerClient.selectPaperSubjectList(sId).then((res) => {
        if (res.code == 0) {
          Vue.prototype.goExam(
            title,
            sId,
            2,
            this.ifClass ? 1 : 3,
            this.IsPurchase,
            res.data.paper.seeNum,
            this.id,
            this.playid,
            this.classId,
            this.cardCode
          )
        }
      })
    },
    closeLook() {
      this.showLookDetail = false
    },
    // 随堂资料
    ktData(item, type) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (!this.IsPurchase) {
        this.dialogVisible = true
        return
      }
      window.open(`/data/dataPackageDetail?courId=${this.id}&type=${type}&ChatId=${item.id}`, '_blank')

      // this.$router.push(`/data/dataPackageDetail?courId=${this.id}&type=${type}&ChatId=${item.id}`);
    }
  }
}
</script>
<style lang="less" scoped>
.warpConss {
  padding: 32px;
  background: #fff;
}

/deep/ .el-collapse {
  border: none !important;

  .el-collapse-item__header {
    height: 46px;
    border: none !important;

    .title-grounp {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 14px;

      .dian {
        width: 6px;
        height: 6px;
        background: #333333;
        opacity: 1;
        border-radius: 50%;
        margin-right: 8px;
        flex-shrink: 0;
      }
    }
  }
}

/* 购买提示 */
.warpCpons {
  // position: absolute;
  position: fixed;
  z-index: 2000;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.6);

  div {
    width: 610px;
    height: auto;
    display: flex;
    z-index: 20;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0);

    .imga {
      width: 100%;
      height: 420px;
    }

    .imgb {
      width: 36px;
      height: 36px;
      margin-top: 30px;
      cursor: pointer;
    }

    span {
      width: 100%;
      height: auto;
      text-align: center;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #6c90fe;
      margin-top: -160px;
    }
  }
}

/* 试听 */
.audition {
  width: 48px;
  height: 20px;
  background: #ffedeb;
  border-radius: 2px 2px 2px 2px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #ff5e51;
  line-height: 20px;
  margin-left: 8px;
  text-align: center;
}

/* 节 */
.canVideo {
  .item-title {
    display: flex;
    width: calc(100% - 300px);
    align-items: center;
    cursor: pointer;

    .iconfont {
      font-size: 14px;
      margin-right: 12px;
      color: #666666 !important;
      flex-shrink: 0;
    }

    .title {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666 !important;
      line-height: 14px;
    }
  }
}

.chat-item {
  border-bottom: 1px solid #eee;
  height: 56px;
  display: flex;
  justify-content: space-between;
  margin: 0px 14px;

  .item-title {
    display: flex;
    width: calc(100% - 300px);
    align-items: center;
    cursor: pointer;

    .iconfont {
      font-size: 14px;
      margin-right: 12px;
      flex-shrink: 0;
      color: #999999 !important;
    }

    .title {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 14px;
    }
  }

  .but-list {
    flex-shrink: 0;
    display: flex;
    width: 240px;
    justify-content: flex-end;
    align-items: center;

    .keep-learn {
      margin-right: 12px;
      flex-shrink: 0;
      width: 72px;
      height: 24px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #ff5e51;
      padding: 0px;
      text-align: center;
      line-height: 24px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;

    .contents {
      width: 380px;
      margin: 20px auto;

      &>span {
        color: red;
      }
    }

    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}

.noLives {
  .keep-learn {
    border: 1px solid #dddddd !important;

    border-radius: 4px 4px 4px 4px;
    color: #aaaaaa;
  }
}
</style>
<style lang="less"></style>
